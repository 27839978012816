import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { startCase } from 'lodash';
import { Box, Typography, CircularProgress, Tooltip } from '@mui/material';

import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Edit from '@mui/icons-material/Edit';
import Lock from '@mui/icons-material/Lock';
import Delete from '@mui/icons-material/Delete';

import WarningIcon from 'src/assets/icons/warning.svg';
import { IconButton } from 'src/components/IconButton';
import { Notice } from 'src/components/Notice/Notice';
import UpdateIPAclDialog from './popup/UpdateIpAcl';
import { TypeToConfirmDialog } from 'src/components/TypeToConfirmDialog/TypeToConfirmDialog';
import { APIError, KafkaStatus, UserPermission } from '@bitnimbus/api-v4';
import { useProfile } from 'src/queries/profile';

const useStyles = makeStyles((theme: Theme) => ({
  columnLabel: {
    color: theme.textColors.headlineStatic,
    fontSize: '1rem',
  },
  warningIcon: {
    color: theme.palette.text.primary,
    width: '32px',
    height: '32px',
  },
  lockIcon: {
    color: theme.palette.text.primary,
  },
  securedMessage: {
    '& svg': {
      height: '20px',
      width: '20px',
    },
  },
}));

interface IpAclProps {
  ipAcl: string[];
  resourceId: string;
  // cloudProvider: Partial<Provider>;
  label: string;
  canUpdate: boolean;
  handleUpdate: (ipAcl: string[]) => Promise<void>;
  error?: APIError[] | null;
  isLoading?: boolean;
  enitiy: string;
}

const IpAcl = (props: IpAclProps) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { data: profile } = useProfile();
  const [open, setOpen] = useState(false);
  const [confirmRemoveIP, setConfirmRemoveIP] = useState(false);
  const [updateError, setUpdateError] = useState('');
  const [errorMap, setErrorMap] = useState<Record<string, string>>({});

  const handleAdd = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setUpdateError('');
    setErrorMap({});
    setOpen(false);
  };

  const updateIp = async (ipList: string[]) => {
    try {
      await props.handleUpdate(ipList);
      enqueueSnackbar(
        ipList.length === 0
          ? `Successfully removed IP Acls from your ${props.enitiy}`
          : `Successfully updated IP Acls to your ${props.enitiy}`,
        {
          variant: 'success',
        }
      );
      setOpen(false);
    } catch (err) {}
  };

  const openConfirmRemoveIP = () => {
    setConfirmRemoveIP(true);
  };

  const removeAllIp = async () => {
    await updateIp([]);
    cancelConfirmRemoveIP();
  };

  const cancelConfirmRemoveIP = () => {
    setConfirmRemoveIP(false);
  };

  const hasIPAcl = () => {
    return props.ipAcl && props.ipAcl.length > 0;
  };

  const canUpdateIpAcl = () => {
    return profile?.permissions === UserPermission.FULL && props.canUpdate;
  };

  setConfirmRemoveIP;
  const ipAclCount = props.ipAcl ? props.ipAcl.length : 0;

  useEffect(() => {
    if (props.error) {
      let errMsg = 'Unabe to update IP Address';
      if (props.error?.length > 0 && props.error[0]?.reason) {
        errMsg = props.error[0].reason;
      }
      setUpdateError(errMsg);
      if (props.error[0].params) {
        setErrorMap({
          ...props.error[0].params?.input,
        });
      }
    }
  }, [props.error]);

  return (
    <Box lineHeight="1.5rem">
      <Box
        component="div"
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Typography variant="h3">IP Access List</Typography>
        {props.isLoading ? (
          <Box component="span">
            <CircularProgress color="primary" size={20} />
          </Box>
        ) : (
          <Box display="flex" justifyContent="flex-end">
            <Tooltip title="Delete IP ACL" placement="top">
              <IconButton
                size="small"
                aria-label="Reset IP"
                onClick={openConfirmRemoveIP}
                disabled={!(canUpdateIpAcl() && hasIPAcl())}
                style={{ padding: 0 }}
                data-testid="ipAcl__delete-button"
              >
                <Delete fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Update Whitelisted IP" placement="top">
              <IconButton
                size="small"
                aria-label="Update IP"
                onClick={handleAdd}
                disabled={!canUpdateIpAcl()}
                style={{ padding: 0, marginLeft: '0.5rem' }}
                data-testid="ipAcl__update-button"
              >
                <Edit fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
      {ipAclCount > 0 ? (
        <Box marginTop={2}>
          <Box
            component="div"
            display="flex"
            alignItems="center"
            padding="0"
            className={classes.securedMessage}
          >
            <Lock fontSize="small" className={classes.lockIcon} />
            <Typography
              variant="subtitle1"
              style={{ textAlign: 'justify', marginLeft: '4px' }}
              className={classes.columnLabel}
            >
              <strong data-testid="ipAcl__count">{ipAclCount}</strong> IP
              {ipAclCount > 1 ? ' addresses are' : ' address is'} added to the
              access list
            </Typography>
          </Box>
          <Box style={{ marginTop: '1.5rem' }}>
            <Typography variant="caption">
              Your {props.enitiy} is configured to accept connections only from
              the IP addresses that are added to the access list
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box component="div">
            <WarningIcon
              className={classes.warningIcon}
              width="40"
              height="40"
            />
          </Box>
          <Box component="div">
            <Typography variant="caption" style={{ textAlign: 'justify' }}>
              Your {props.enitiy} is set to allow all connections, which is
              unsafe. We recommend you to create IP access list to accept
              connections from your trusted source.
            </Typography>
          </Box>
        </Box>
      )}
      <UpdateIPAclDialog
        open={open}
        handleClose={handleClose}
        isLoading={props.isLoading || false}
        ipAcl={props.ipAcl}
        updateIp={updateIp}
        error={updateError}
        errorMap={errorMap}
      />
      <TypeToConfirmDialog
        title={`Remove IP Acl?`}
        entity={{ type: 'Cluster', label: props.label }}
        open={confirmRemoveIP}
        loading={props.isLoading || false}
        errors={props.error}
        onClose={cancelConfirmRemoveIP}
        isActionEnabled={true}
        onClick={removeAllIp}
        confirmationText={
          <Typography marginTop={1}>
            If yes, Please enter the cluster label (
            <strong>{props.label}</strong>) to proceed.
          </Typography>
        }
      >
        <Notice error>
          <Typography variant="caption">
            Removing the IP Access List will allow all connections to your{' '}
            {startCase(props.enitiy)}
          </Typography>
        </Notice>
        <Box marginTop={2} marginLeft={0}>
          <Typography variant="body1">Are you sure want to proceed?</Typography>
        </Box>
      </TypeToConfirmDialog>
    </Box>
  );
};

export default React.memo(IpAcl);
