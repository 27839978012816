import React, { ReactNode } from 'react';
import { capitalize } from 'lodash';

import { pluralize } from 'src/utilities/pluralize';
import formatDate from 'src/utilities/formatDate';
import { Nodes } from '@bitnimbus/api-v4/lib/kafka/types';
import { CopyTooltip } from 'src/components/CopyTooltip/CopyTooltip';
import LabelValueDisplay from 'src/components/LabelValueDisplay';

import { Box, Typography, Divider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { makeStyles } from '@mui/styles';
import { Theme, useTheme } from '@mui/material/styles';
import { Plan } from '@bitnimbus/api-v4';

export interface Spec {
  icon: ReactNode;
  label: string;
  value: string | number;
}

export interface SummaryProps {
  instances?: number;
  region: string | null;
  resourceId: string;
  createdOn: string;
  createdBy: string;
  label: string;
  status: string;
  cloudProvider: string;
  specs?: Spec[];
  plan: string;
  price_hourly: number;
}

const useBodyStyles = makeStyles((theme: Theme) => ({
  chipWrapper: {
    [theme.breakpoints.up('sm')]: {
      '&.MuiGrid-item': {
        marginTop: 2,
      },
    },
  },
  embeddedGrid: {
    paddingLeft: 0,
  },
  statusChip: {
    ...theme.applyStatusPillStyles,
    letterSpacing: '.5px',
    borderRadius: 0,
    height: `24px !important`,
    marginTop: '12px',
  },
  statusChipLandingDetailView: {
    [theme.breakpoints.down('lg')]: {
      marginLeft: theme.spacing(),
    },
  },
  statusRunning: {
    '&:before': {
      backgroundColor: theme.color.green,
    },
  },
  statusOffline: {
    '&:before': {
      backgroundColor: theme.color.red,
    },
  },
  statusOther: {
    '&:before': {
      backgroundColor: theme.color.orange,
    },
  },
  body: {
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
  },
  columnLabel: {
    color: theme.textColors.headlineStatic,
    // fontFamily: theme.font.bold,
  },
  // summaryContainer: {
  //     flexBasis: '25%',
  // },
  summaryContent: {
    '& > div': {
      flexBasis: '50%',
      [theme.breakpoints.down('md')]: {
        flexBasis: '100%',
      },
    },
    '& p': {
      color: theme.textColors.tableStatic,
    },
  },
  rightColumn: {
    flexBasis: '75%',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  clusterIdCopyText: {
    color: theme.textColors.textGrayShade,
  },
  girdSection: {
    paddingTop: 0,
  },
  specItem: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1.5),
  },
}));

export const ResourceSpec = React.memo((props: SummaryProps) => {
  const classes = useBodyStyles();
  const {
    instances,
    region,
    createdOn,
    cloudProvider,
    resourceId,
    specs,
    plan,
  } = props;

  const theme = useTheme();

  const sxLabel = {
    color: theme.textColors.textGrayShade,
    marginLeft: theme.spacing(1),
  };
  return (
    <Box component="div">
      <Box component="div" marginLeft={1}>
        <LabelValueDisplay
          label="Resource ID"
          inline={true}
          component={
            <span>
              <CopyTooltip
                text={resourceId}
                copyableText
                className={classes.clusterIdCopyText}
              />
              <Box component="span" marginLeft="8px" marginTop="4px">
                <CopyTooltip text={resourceId} />
              </Box>
            </span>
          }
        />
      </Box>
      <Grid
        container
        className={classes.body}
        direction="row"
        spacing={1}
        flexWrap="wrap"
      >
        <Grid container xs={3} flexWrap="wrap">
          <Box component="div" style={{ width: '100%', paddingTop: '4px' }}>
            <Grid
              container
              direction="column"
              flexWrap="wrap"
              spacing={1}
              wrap="wrap"
              marginLeft={0}
            >
              <Grid>
                {/* <Box component="div"> */}
                <Typography variant="h3" className={classes.columnLabel}>
                  Specs
                </Typography>
                <Box
                  component="div"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                  }}
                >
                  {/* <Grid container> */}
                  {props.specs &&
                    props.specs.map((spec) => (
                      // <Grid sm={6}>
                      <Box
                        component="div"
                        className={classes.specItem}
                        key={spec.label}
                      >
                        {spec.icon}

                        <Typography sx={sxLabel}>
                          <Box component="span">
                            {spec.value} {spec.label}
                          </Box>
                        </Typography>
                      </Box>
                      // </Grid>
                    ))}
                  {/* </Grid> */}
                  {/* <Box component="div">
                      {instances && (
                        <Box component="div" className={classes.specItem}>
                          <NodesIcon />
                          <Typography sx={sxLabel}>
                            <Box component="span">
                              {`${pluralize('Node', 'Nodes', instances)}`}
                            </Box>
                          </Typography>
                        </Box>
                      )}
                      <Box component="div" className={classes.specItem}>
                        <RAMIcon />
                        <Typography sx={sxLabel}>
                          <Box component="span">
                            {`${specs?.memory || '0 GB'} RAM`}
                          </Box>
                        </Typography>
                      </Box>
                    </Box>
                    <Box component="div">
                      <Box className={classes.specItem}>
                        <CPUIcon />
                        <Typography sx={sxLabel}>
                          <Box component="span">{`${specs?.vcpu} vCPU`}</Box>
                        </Typography>
                      </Box>
                      <Box component="div" className={classes.specItem}>
                        <StorageIcon />
                        <Typography sx={sxLabel}>
                          <Box component="span">
                            {`${specs?.disk || '0 GB'} Storage`}
                          </Box>
                        </Typography>
                      </Box>
                    </Box> */}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={1} justifyContent="flex-start">
          <Divider
            orientation="vertical"
            textAlign="center"
            style={{ marginRight: '2rem' }}
          />
          <div />
        </Grid>
        <Grid container xs={8} wrap="wrap">
          <Box component="div" style={{ width: '100%' }}>
            <Grid
              container
              direction="row"
              spacing={1}
              justifyContent="space-between"
            >
              <Grid xs={4} wrap="wrap">
                <Box
                  component="div"
                  style={{ width: '100%' }}
                  className={classes.girdSection}
                >
                  <Grid container direction="column" spacing={1}>
                    <Grid>
                      <LabelValueDisplay
                        label="Cloud Provider"
                        value={capitalize(cloudProvider)}
                        inline={false}
                      />
                    </Grid>

                    <Grid>
                      <LabelValueDisplay
                        label="Price"
                        value={`$ ${Number(props?.price_hourly || 0)} /hour`}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid xs={4}>
                <Grid
                  container
                  direction="column"
                  spacing={1}
                  className={classes.girdSection}
                >
                  <Grid>
                    <LabelValueDisplay label="Region" value={region || ''} />
                  </Grid>
                  <Grid>
                    <LabelValueDisplay label="Plan" value={plan || ''} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={4}>
                <Grid
                  container
                  direction="column"
                  spacing={1}
                  className={classes.girdSection}
                >
                  <Grid>
                    <LabelValueDisplay
                      label="Created"
                      value={`${formatDate(createdOn, {
                        timezone: 'utc',
                      })} UTC`}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
});

export default ResourceSpec;
