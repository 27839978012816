import {
  APIError,
  CreateResourceResponse,
  CreateVectorDBRequest,
  PlansResponse,
  createVectorDB,
  getVectorDBPlans,
  VectorDB,
  listVectorDB,
  Resource,
  getVectorDB,
  VectorDBResponse,
  UpdateVectorDBRequest,
  updateVectorDB,
  deleteVectorDB,
  Stats,
} from '@bitnimbus/api-v4';
import { DateTime } from 'luxon';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { parseAPIDate } from 'src/utilities/date';
import { STATS_NOT_READY_MESSAGE } from './kafka';

const QUERY_KEY = 'vectordb';

export const useCreateVectordbMutation = () => {
  const queryClient = useQueryClient();
  return useMutation<CreateResourceResponse, APIError[], CreateVectorDBRequest>(
    async (data) => {
      const response = await createVectorDB(data);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEY, 'all']);
        queryClient.invalidateQueries([QUERY_KEY]);
        queryClient.invalidateQueries(['account', 'usage']);
      },
    }
  );
};

export const usePlansQuery = () => {
  return useQuery<PlansResponse, APIError[]>([QUERY_KEY, 'plans'], async () => {
    const result = await getVectorDBPlans();
    return result.data;
  });
};

export const useListVectorDBQuery = () => {
  return useQuery<Resource[] | undefined, APIError[]>(
    [QUERY_KEY, 'list'],
    async () => {
      const result = await listVectorDB();
      return result.data;
    },
    {
      refetchInterval: 20000,
    }
  );
};

export const useVectorDBQuery = (id: string) => {
  return useQuery<VectorDBResponse | undefined, APIError[]>(
    [QUERY_KEY, id],
    async () => {
      const result = await getVectorDB(id);
      return result.data;
    },
    {
      refetchInterval: 20000,
    }
  );
};

export const useUpdateVectorDBMutation = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation<VectorDB | null, APIError[], UpdateVectorDBRequest>(
    async (data) => {
      const result = await updateVectorDB(id, data);
      return result.data || null;
    },
    {
      onSuccess: (result) => {
        const vectordb = (result as unknown) as Resource;
        const cache = queryClient.getQueryData([QUERY_KEY, id]);
        if (cache) {
          queryClient.setQueryData([QUERY_KEY, id], {
            ...cache,
            label: vectordb.label,
            acl_ips: result?.acl_ips,
            status: vectordb?.status,
          });
        }
        queryClient.invalidateQueries([QUERY_KEY, 'list']);
      },
    }
  );
};

export const useDeleteVectorDBMutation = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation<Partial<VectorDB>, APIError[]>(
    async (data) => {
      const result = await deleteVectorDB(id);
      return result.data || {};
    },
    {
      onSuccess: (result) => {
        const vectordb = (result as unknown) as Resource;
        const cache = queryClient.getQueryData([QUERY_KEY, id]);
        if (cache) {
          queryClient.setQueryData([QUERY_KEY, id], {
            ...cache,
            status: vectordb.status,
          });
          queryClient.invalidateQueries([QUERY_KEY, 'all']);
        }
      },
    }
  );
};
