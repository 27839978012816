import React from 'react';
import { makeStyles } from '@mui/styles';
import KafkaIcon from 'src/assets/icons/entityIcons/kafka.svg';
import VectorDBIcon from 'src/assets/icons/entityIcons/vectordb.svg';
import { Box } from 'src/components/Box';
import { Typography } from 'src/components/Typography';
import Paper from 'src/components/core/Paper';
import { Theme } from '@mui/material';
import { Redirect, useHistory } from 'react-router-dom';
import { useAllKafkaQuery } from 'src/queries/kafka';
import { useListVectorDBQuery } from 'src/queries/vectordb';
import SuspenseLoader from 'src/components/SuspenseLoader';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    cursor: 'pointer',
    '&:hover': {
      outline: `2px solid ${theme.palette.primary.main}`,
    },
    padding: '4rem 2rem',
  },
  cardContent: {
    margin: '4rem 2rem',
  },
}));

const ProductLanding = () => {
  const classes = useStyles();
  const history = useHistory();
  const { data: kafkaList, isLoading: kafkaLoading } = useAllKafkaQuery();
  const {
    data: vectordbList,
    isLoading: vectordbLoading,
  } = useListVectorDBQuery();
  if (kafkaLoading || vectordbLoading) {
    return <SuspenseLoader />;
  }
  if (kafkaList && kafkaList.length > 0) {
    return <Redirect to="/kafka" />;
  }
  if (vectordbList && vectordbList.length > 0) {
    return <Redirect to="/vectordb" />;
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80vh',
      }}
    >
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, minmax(0, 1fr)',
          gridAutoRows: '1fr',
          gridAutoColumns: '1fr',
          gap: '2rem',
          margin: 'auto',
        }}
      >
        <Paper
          className={classes.card}
          onClick={() => history.push('/kafka/create')}
        >
          <Box>
            <KafkaIcon style={{ height: '190px', margin: 'auto' }} />
          </Box>
          <Box marginTop="3rem">
            <Typography textAlign="center" fontSize="1.2rem">
              Deploy a fully managed Kafka Cluster based on your workload needs
            </Typography>
          </Box>
        </Paper>

        <Paper
          className={classes.card}
          onClick={() => history.push('/vectordb/create')}
        >
          <Box>
            <VectorDBIcon style={{ height: '190px', margin: 'auto' }} />
          </Box>
          <Box marginTop="3rem">
            <Typography textAlign="center" fontSize="1.2rem">
              Deploy a fully managed VectorDB based on your workload needs
            </Typography>
          </Box>
          <Box marginTop="1rem">
            <Typography textAlign="center">Powered by Chroma</Typography>
          </Box>
        </Paper>
      </div>
    </div>
  );
};

export default ProductLanding;
