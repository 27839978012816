import React from 'react';
import { Box, Divider, Grid, Paper } from '@mui/material';
import classNames from 'classnames';
import { CopyTooltip } from 'src/components/CopyTooltip/CopyTooltip';
import MaskText from 'src/components/MaskText/MaskText';
import { EntityHeader } from 'src/components/EntityHeader/EntityHeader';
import LabelValueDisplay from 'src/components/LabelValueDisplay';
import { Typography } from 'src/components/Typography';

import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import {
  UserPermission,
  VectorDBStatus,
  VectorDBUser,
} from '@bitnimbus/api-v4';
import IpAcl from 'src/features/IpAcl';
import { useUpdateVectorDBMutation } from 'src/queries/vectordb';
import { useProfile } from 'src/queries/profile';

const useStyles = makeStyles((theme: Theme) => ({
  maskText: {
    color: theme.textColors.headlineStatic,
  },
  brokerServerContainer: {
    maxHeight: '4rem',
    overflowY: 'auto',
    margin: '0.5rem',
    marginBottom: '0',
    marginTop: '0',
    borderRadius: theme.spacing(1),
    backgroundColor: '#BFC3CA80',
    padding: '0.5rem',
    marginLeft: theme.spacing(2),
  },
  brokerServerLabel: {
    textWrap: 'nowrap',
  },
  emptyBootstrapServer: {
    margin: theme.spacing(2),
  },
}));

interface VectorDBConnectionDetailsProps {
  user: VectorDBUser | null;
  connection_string: string;
  id: string;
  acl_ips?: string[];
  label: string;
  status: VectorDBStatus;
}

const VectorDBConnectionDetails = (props: VectorDBConnectionDetailsProps) => {
  const classes = useStyles();

  const { user, connection_string } = props;
  const { data: profile } = useProfile();
  const {
    mutateAsync: updateVectordb,
    error: updateError,
    isLoading: updateLoading,
  } = useUpdateVectorDBMutation(props.id);

  const handleUpdate = async (ipAcl: string[]) => {
    await updateVectordb({
      acl_ips: ipAcl,
    });
  };

  const canUpdate = () => {
    return (
      profile.permissions === UserPermission.FULL &&
      props.status === VectorDBStatus.ACTIVE
    );
  };

  return (
    <Paper style={{ margin: 12, padding: 8 }}>
      <Box>
        <EntityHeader title="Connection Info" isSummaryView={true} />
      </Box>
      <Divider style={{ margin: 0 }} />
      <Grid flexWrap={'wrap'} container xs={12} md={12} marginBottom={0}>
        <Grid sm={7} margin={1} marginTop={3} spacing={2}>
          <Box marginBottom="8px">
            <LabelValueDisplay
              label="Username"
              component={
                <Box display="flex">
                  <CopyTooltip text={user?.username || ''} copyableText />
                  <Box component="div" marginLeft="1rem">
                    <CopyTooltip text={user?.username || ''} />
                  </Box>
                </Box>
              }
              inline={true}
            />
          </Box>
          <Box marginBottom="8px">
            <LabelValueDisplay
              label="Password"
              component={
                <Box component="span" display="flex" alignItems="baseline">
                  <MaskText
                    text={user?.password || ''}
                    className={classes.maskText}
                  />
                  <Box component="span" marginLeft="1rem">
                    <CopyTooltip text={user?.password || ''} />
                  </Box>
                </Box>
              }
              inline={true}
            />
          </Box>
          <Box display="flex" alignItems="baseline">
            <Box
              className={classNames({
                [classes.brokerServerLabel]: connection_string !== '',
              })}
            >
              <Typography variant="h3">Endpoint</Typography>
            </Box>
            <Box
              component="div"
              className={classNames({
                [classes.brokerServerContainer]: connection_string !== '',
                [classes.emptyBootstrapServer]: connection_string === '',
              })}
            >
              <Typography variant="body1">
                {connection_string.length === 0
                  ? 'Will be available shortly'
                  : `https://${connection_string}`}
              </Typography>
            </Box>
            <Box>
              <CopyTooltip
                text={
                  connection_string.length === 0
                    ? 'Database Unavailable'
                    : connection_string
                }
              />
            </Box>
          </Box>
        </Grid>
        <Grid sm={0.1}>
          <Divider orientation="vertical" textAlign="center" />
        </Grid>
        <Grid sm={4.4} margin={2}>
          <IpAcl
            resourceId={props.id || ''}
            ipAcl={props.acl_ips || []}
            label={props.label || ''}
            canUpdate={canUpdate()}
            isLoading={updateLoading}
            error={updateError}
            handleUpdate={handleUpdate}
            enitiy="VectorDB"
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default VectorDBConnectionDetails;
