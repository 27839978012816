import React, { useState, useEffect, KeyboardEvent } from 'react';
import cx from 'classnames';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Box,
  TextField,
  CircularProgress,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import Tooltip from 'src/components/core/Tooltip';
import { Button } from 'src/components/Button/Button';
import { Chip } from 'src/components/Chip';
import { Notice } from 'src/components/Notice/Notice';
import Grid from '@mui/material/Unstable_Grid2/Grid2';

export interface IPAcl {
  [ip: string]: boolean;
}

interface UpdateWhitelistProps {
  updateIp: (ipAcl: string[]) => void;
  handleClose: () => void;
  open: boolean;
  isLoading: boolean;
  ipAcl: string[];
  error: string;
  errorMap: Record<string, string>;
}

const useStyles = makeStyles((theme: Theme) => ({
  addIpTextbox: {
    '& > .MuiFormHelperText-root': {
      marginLeft: 0,
    },
  },
  ipText: {
    fontSize: '1rem',
    padding: '1.2rem',
  },
  errorIp: {
    color: theme.color.red,
  },
}));

const UpdateIPAclDialog = (props: UpdateWhitelistProps) => {
  const classes = useStyles();

  const { updateIp, open, isLoading } = props;

  const [newIpSet, setNewIpSet] = useState('');
  const [isUpated, setIsUpdated] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [ipAcl, setIpAcl] = useState<Record<string, boolean>>({});

  const getIpObj = (ipAcl: string[]) => {
    const obj: Record<string, boolean> = {};
    ipAcl.forEach((ip) => (obj[ip] = true));
    return obj;
  };

  useEffect(() => {
    if (props.ipAcl) {
      const obj = getIpObj(props.ipAcl);
      setIpAcl({
        ...obj,
      });
    } else {
      setIpAcl({});
    }
  }, [props.ipAcl]);

  const handleClose = () => {
    setSearchTerm('');
    const obj = getIpObj(props.ipAcl);
    setIpAcl({
      ...obj,
    });
    props.handleClose();
  };

  const handleChange = (e: any) => {
    setNewIpSet(e.target.value);
  };

  const handleAddIp = () => {
    if (newIpSet.trim().length === 0) {
      return;
    }
    const ipList = newIpSet.split(',');
    const temp = ipAcl;
    ipList.forEach((ip) => {
      const trimmedIp = ip.trim();
      temp[trimmedIp] = true;
    });
    setIpAcl({
      ...temp,
    });
    setNewIpSet('');
    setIsUpdated(true);
  };

  const handleRemoveIp = (ip: string) => () => {
    setIpAcl({
      ...ipAcl,
      [ip]: false,
    });
    setIsUpdated(true);
  };

  const handleSearch = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddIp();
    }
  };

  const handleUpdate = async () => {
    const ipList = Object.keys(ipAcl).filter((ip) => ipAcl[ip] === true);
    await updateIp(ipList);
    setSearchTerm('');
  };

  return (
    <Dialog open={open} maxWidth="xl">
      <DialogTitle style={{ marginBottom: 0 }}>
        <Typography variant="h2">Update IP Access List</Typography>
      </DialogTitle>
      <DialogContent>
        <Box width="50vw">
          <Box
            component="div"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <TextField
              value={newIpSet}
              size="small"
              multiline
              rows={3}
              maxRows={3}
              name="IP Address"
              fullWidth
              onChange={handleChange}
              helperText="Example: 192.168.1.1,172.14.26.18"
              placeholder="Enter IP Address separated by comma"
              data-testid="update-acl__ip-input"
              onKeyPress={handleKeyPress}
              className={classes.addIpTextbox}
            />
            <Button
              buttonType="primary"
              style={{ marginLeft: '1rem' }}
              onClick={handleAddIp}
              disabled={!newIpSet.trim()}
              data-testid="update-acl__add-button"
            >
              Add IP
            </Button>
          </Box>
          <Box component="div" height="40vh" marginTop="1rem">
            <Typography variant="h3">IP Access List</Typography>
            <Box component="div">
              <TextField
                placeholder="Search IP"
                size="small"
                fullWidth
                type="search"
                onChange={handleSearch}
              />
            </Box>
            <Box component="div" display="flex" overflow="auto" flexWrap="wrap">
              {ipAcl &&
                Object.keys(ipAcl).map(
                  (ip) =>
                    ipAcl[ip] &&
                    (!searchTerm || ip.startsWith(searchTerm)) && (
                      <Box
                        key={ip}
                        component="div"
                        margin="8px"
                        fontSize="1rem"
                      >
                        <Chip
                          // title={props.errorMap[ip]}
                          style={{ borderRadius: '4px' }}
                          error={props.errorMap[ip] !== undefined}
                          label={
                            <Tooltip title={props.errorMap[ip]} role="tooltip">
                              <Typography
                                variant="body1"
                                className={cx({
                                  [classes.ipText]: true,
                                  [classes.errorIp]:
                                    props.errorMap[ip] !== undefined,
                                })}
                              >
                                {ip}
                              </Typography>
                            </Tooltip>
                          }
                          variant="outlined"
                          size="medium"
                          onDelete={handleRemoveIp(ip)}
                          type="default"
                          data-testid={`update-acl__ip-chip-${ip}`}
                        />
                      </Box>
                    )
                )}
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="flex-end" width="50vw">
          <Box>{props.error && <Notice error>{props.error}</Notice>}</Box>
          <Box>
            <Box display="flex" justifyContent="flex-end">
              <Button buttonType="text" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                buttonType="primary"
                onClick={handleUpdate}
                disabled={!isUpated || isLoading}
                data-testid="update-acl__submit"
              >
                {isLoading && <CircularProgress color="primary" size={20} />}
                Update
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateIPAclDialog;
