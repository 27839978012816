/**
 * EntityDetail provides a framework for the "Detail Summary" components found on:
 *  1. Detail Pages
 *  2. List Pages
 *  3. Dashboard
 * Provide a Header, Body, and Footer and this component provides the proper positioning for each.
 */

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '../core/Paper';
import { Divider } from '@mui/material';

export interface EntityDetailProps {
  header: JSX.Element;
  body?: JSX.Element;
  footer?: JSX.Element;
}

export const EntityDetail = (props: EntityDetailProps) => {
  const { header, body, footer } = props;

  return (
    <Paper style={{margin: 12, padding: 10}}>
      {header}
      <Divider style={{ margin: 0 }} />
      {body !== undefined && <GridBody xs={12}>{body}</GridBody>}
      {
        footer !== undefined && (
          <React.Fragment>
            <Divider />
            <GridFooter xs={12} body={body}>
              {footer}
            </GridFooter>
          </React.Fragment>
        )
      }
    </Paper>
  );
};

const GridBody = styled(Grid)(({ theme }) => ({
  borderBottom: `1px solid ${theme.borderColors.borderTable}`,
  //borderTop: `1px solid ${theme.borderColors.borderTable}`,
  paddingBottom: theme.spacing(),
  paddingRight: theme.spacing(),
}));

const GridFooter = styled(Grid)<Partial<EntityDetailProps>>(
  ({ theme, ...props }) => ({
    alignItems: 'center',
    borderTop:
      props.body === undefined
        ? `1px solid ${theme.borderColors.borderTable}`
        : undefined,
    display: 'flex',
    flexDirection: 'row',
    padding: `0 15px 0px`,
  })
);

export default EntityDetail;
