import * as React from 'react';
import { Typography } from 'src/components/Typography';
import { Notice } from 'src/components/Notice/Notice';
import { TypeToConfirmDialog } from 'src/components/TypeToConfirmDialog/TypeToConfirmDialog';
import { resetEventsPolling } from 'src/eventsPolling';
import { Kafka } from '@bitnimbus/api-v4/lib/kafka/types';
import { APIError } from '@bitnimbus/api-v4';

interface Props {
  resourceId: string;
  label: string;
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  handleDelete: () => Promise<void>;
  reset: () => void;
  enabled?: boolean;
  disabledMessage?: string;
  isLoading?: boolean;
  error: APIError[] | null;
}

const DeleteResourceDialog = (props: Props) => {
  const [label, setLabel] = React.useState<string>(props.label || '');
  const { open, onClose, onSuccess } = props;

  React.useEffect(() => {
    if (open) {
      props.reset();
    }
  }, [open]);

  React.useEffect(() => {
    setLabel(props.label);
  }, [props.label]);

  const onDelete = async () => {
    await props.handleDelete();
    onClose();
    resetEventsPolling();

    if (onSuccess) {
      onSuccess();
    }
  };

  return (
    <TypeToConfirmDialog
      title={`Delete ${label}?`}
      entity={{ type: 'Cluster', label }}
      open={open}
      loading={props.isLoading || false}
      errors={props.error}
      onClose={onClose}
      onClick={onDelete}
      isActionEnabled={props.enabled}
    >
      {props.enabled ? (
        <Notice error>
          <Typography variant="caption">
            <strong>Warning:</strong>&nbsp;The cluster and data will be
            permanently deleted
          </Typography>
        </Notice>
      ) : (
        <Notice error>
          <Typography variant="caption">
            <strong>Error:</strong> {props.disabledMessage}
          </Typography>
        </Notice>
      )}
    </TypeToConfirmDialog>
  );
};

export default DeleteResourceDialog;
