import * as React from 'react';
import { Theme, styled } from '@mui/material/styles';

export type Status = string;

export interface StatusProps {
  status: Status;
  pulse?: boolean;
}

const inActiveStatus = ["offline", "stopped", "deleted"];

const getColorForStatus = (theme: Theme, status: string) => {
  const activeColor = "#1D9000";
  const inActiveColor = "#EE4B2B";
  const failedColor = theme.color.red;
  const defaultColor = theme.color.orange;
  if (status === "running") {
    return activeColor;
  }
  if (status === "deleting") {
    return inActiveColor;
  }
  if (inActiveStatus.includes(status)) {
    return inActiveColor;
  }
  if (status === "failed") {
    return failedColor;
  }
  return defaultColor;
}

const StatusIcon = React.memo((props: StatusProps) => {
  const { status, pulse } = props;

  const shouldPulse =
    pulse === undefined
      ? // If pulse is not defined, use old behavior for choosing when to pulse
        ![...inActiveStatus, 'running', 'failed'].includes(status)
      : pulse;

  return <StyledDiv pulse={shouldPulse} status={status} />;
});

export { StatusIcon };

const StyledDiv = styled('div')<StatusProps>(({ theme, ...props }) => ({
  display: 'inline-block',
  borderRadius: '50%',
  height: '16px',
  width: '16px',
  marginRight: theme.spacing(),
  position: 'relative',
  transition: theme.transitions.create(['color']),
  backgroundColor: getColorForStatus(theme, props.status),
  ...(props.pulse && {
    animation: 'pulse 1.5s ease-in-out infinite',
  }),
}));
