import {
  APIError,
  ServiceType,
  Stats,
  getKafkaStatsByRange,
  getVectorDB,
  getVectorDBMetrics,
} from '@bitnimbus/api-v4';
import { DateTime } from 'luxon';
import { useQuery } from 'react-query';
import { parseAPIDate } from 'src/utilities/date';

const QUERY_KEY = 'metrics';
export const STATS_NOT_READY_MESSAGE =
  'Stats for this Kafka Cluster are not available yet';

export const useMetricsQuery = (
  id: string,
  startTime: Date,
  endTime: Date,
  createdAt: string,
  service: ServiceType
) => {
  const isTooEarlyForStats =
    parseAPIDate(createdAt) > DateTime.local().minus({ minutes: 7 });
  return useQuery<Stats[], APIError[]>([QUERY_KEY, service, id], async () => {
    if (isTooEarlyForStats) {
      return Promise.reject([{ reason: STATS_NOT_READY_MESSAGE }]);
    }
    let requestFn;
    if (service === ServiceType.KAFKA) {
      requestFn = getKafkaStatsByRange;
    } else {
      requestFn = getVectorDBMetrics;
    }
    const result = await requestFn(
      id,
      startTime.getTime() / 1000,
      endTime.getTime() / 1000
    );
    return result.data || [];
  });
};
