import {
  Account,
  PlanUsage,
  deleteAccount,
  getAccountInfo,
  updateAccountInfo,
  getAccountUsage,
} from '@bitnimbus/api-v4';
import { APIError, Response } from '@bitnimbus/api-v4/lib/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useProfile } from 'src/queries/profile';
import { queryPresets } from './base';
import { Feedback } from '@bitnimbus/api-v4';

const baseKey = ['account'];
export const GetAccountInfoKey = [...baseKey];
export const GetAccountUsageKey = [...baseKey, 'usage'];

export const useAccount = () => {
  const accountResponse = useQuery<Response<Account>, APIError[]>(
    GetAccountInfoKey,
    getAccountInfo,
    {
      ...queryPresets.oneTimeFetch,
      ...queryPresets.noRetry,
    }
  );
  return {
    ...accountResponse,
    data: accountResponse?.data?.data,
  };
};

export const useMutateAccount = () => {
  const queryClient = useQueryClient();

  return useMutation<Account, APIError[], Partial<Account>>(updateAccountInfo, {
    onSuccess(account) {
      queryClient.setQueryData(GetAccountInfoKey, account);
    },
  });
};

export const useDeleteAccountMutation = () => {
  return useMutation<{}, APIError[], Feedback>(deleteAccount);
};

export const useAccountUsage = () => {
  const response = useQuery<Response<PlanUsage>, APIError[]>(
    GetAccountUsageKey,
    getAccountUsage,
    {
      ...queryPresets.oneTimeFetch,
    }
  );
  return {
    ...response,
    data: response.data?.data,
  };
};
