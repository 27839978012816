import { Kafka, UserPermission } from '@bitnimbus/api-v4';
import * as React from 'react';
import EntityDetail from 'src/components/EntityDetail';
import { EntityHeader } from 'src/components/EntityHeader/EntityHeader';
import { IconButton } from 'src/components/IconButton';
import { TypographyProps } from 'src/components/Typography';
import { Resource } from '@bitnimbus/api-v4';
// import { KafkaHandlers } from '../types';
import { notificationContext as _notificationContext } from 'src/features/NotificationCenter/NotificationContext';

import Delete from '@mui/icons-material/Delete';

import { useProfile } from 'src/queries/profile';
import ResourceSpec, { Spec } from './ResourceSpec';

interface KafkaEntityDetailProps {
  variant?: TypographyProps['variant'];
  resource: Resource;
  isSummaryView?: boolean;
  instances?: number;
  price_hourly: number;
  specs?: Spec[];
  title: string;
  plan: string;
  onDeleteOpen: () => void;
}

export type Props = KafkaEntityDetailProps & {
  //   handlers: KafkaHandlers;
};

const ResourceDetails = (props: Props) => {
  const { resource } = props;
  const { data: profile } = useProfile();

  return (
    <EntityDetail
      header={
        <EntityHeader title={props.title} variant="h1" isSummaryView={true}>
          <IconButton
            onClick={props.onDeleteOpen}
            disabled={profile.permissions !== UserPermission.FULL}
            data-testid="kafka__delete-button"
          >
            <Delete />
          </IconButton>
        </EntityHeader>
      }
      body={
        <ResourceSpec
          resourceId={resource.id}
          label={resource.label}
          region={resource.region}
          createdOn={resource.created_on}
          createdBy={resource.created_by_id}
          status={resource.status}
          cloudProvider={resource.cloud_provider}
          specs={props.specs}
          price_hourly={props.price_hourly}
          plan={props.plan}
        />
      }
    />
  );
};

export default ResourceDetails;
