import { APIError } from '@bitnimbus/api-v4/lib/types';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { capitalize, startCase } from 'lodash';
import { CircleProgress } from 'src/components/CircleProgress';
import { ErrorState } from 'src/components/ErrorState/ErrorState';
import useEditableLabelState from 'src/hooks/useEditableLabelState';
import { getQueryParamsFromQueryString } from 'src/utilities/queryParams';

import { Breadcrumb } from 'src/components/Breadcrumb/Breadcrumb';
import { StatusIcon } from 'src/components/StatusIcon/StatusIcon';
import RefreshButton from 'src/components/RefreshButton/RefreshButton';
import { Box, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Notice } from 'src/components/Notice/Notice';
import { Resource } from '@bitnimbus/api-v4';
import { useProfile } from 'src/queries/profile';

interface Props {
  resource: Resource | null;
  error: APIError[] | null;
  isLoading: boolean;
  refetchResource: () => Promise<void>;
  isRefetching?: boolean;
  isFailed?: boolean;
  canUpdate: boolean;
  updateLabel: (label: string) => Promise<void>;
  updateCompleted: boolean;
  path: string;
}

const Header = (props: Props) => {
  const queryParams = getQueryParamsFromQueryString(location.search);
  const { data: profile } = useProfile();
  const { enqueueSnackbar } = useSnackbar();
  //   const {
  //     mutateAsync: updateKafka,
  //     error: updateError,
  //   } = useUpdateKafkaMutation(props.kafka?.id || '');

  const { resource, error, isLoading, isRefetching } = props;

  //   const refreshKafka = async () => {
  //     await props.refetchKafka();
  //   };

  const {
    editableLabelError,
    resetEditableLabel,
    setEditableLabel,
  } = useEditableLabelState();

  //   React.useEffect(() => {
  //     if (updateError) {
  //       enqueueSnackbar(updateError[0].reason, {
  //         variant: 'error',
  //       });
  //     }
  //   }, [updateError]);

  //   const updateKafkaLabel = async (label: string) => {
  //     await updateKafka({ label });
  //     enqueueSnackbar('Sucessfully updated cluster label', {
  //       variant: 'success',
  //     });
  //     resetEditableLabel();
  //   };

  React.useEffect(() => {
    if (props.updateCompleted) {
      // resetEditableLabel();onEditHandlers
    }
  }, [props.updateCompleted]);

  if (!resource) {
    return null;
  }

  const editableProps = {
    editableTextTitle: resource?.label ?? '',
    onEdit: props.updateLabel,
    onCancel: resetEditableLabel,
    errorText: editableLabelError,
  };

  const sxStatusText = {
    fontWeight: '500',
    fontSize: '1.2rem',
  };

  return (
    <>
      <Box
        component="div"
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        width="100%"
      >
        <Box component="div">
          <Breadcrumb
            data-qa-title
            labelTitle={resource?.label}
            pathname={props.path}
            onEditHandlers={props?.canUpdate ? editableProps : undefined}
          />
        </Box>
        <Box component="span" display="flex" alignItems="center" marginLeft={1}>
          <Box component="span">
            <StatusIcon status={resource.status} />
          </Box>
          <Typography variant="subtitle1" sx={sxStatusText}>
            <Box component="span">{startCase(resource.status)}</Box>
          </Typography>
          <RefreshButton
            onClick={props.refetchResource}
            loading={isLoading || isRefetching}
          />
        </Box>
        {props.isFailed && (
          <Box marginLeft={2}>
            <Notice error>
              Ouch! Your build failed and we are researching the cause. Choose
              another data center and/or provider. Feel free to contact us at
              &nbsp;
              <a href="malto:support@bitnimbus.io">support@bitnibmus.io</a>
            </Notice>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Header;
