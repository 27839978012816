import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import SuspenseLoader from 'src/components/SuspenseLoader';
import VectorDBDetail from './VectordbDetail';

const VectordbCreate = React.lazy(() => import('./VectordbCreate'));
const VectordbLanding = React.lazy(() => import('./VectordbLanding'));

const VectordbRoutes = () => {
  return (
    <React.Suspense fallback={SuspenseLoader}>
      <Switch>
        <Route component={VectordbCreate} path="/vectorDB/create" />
        <Route component={VectordbLanding} path="/vectorDB" exact strict />
        <Route component={VectorDBDetail} path="/vectorDB/:vectordbId" />
        <Redirect to="/vectorDB" />
      </Switch>
    </React.Suspense>
  );
};

export default VectordbRoutes;
