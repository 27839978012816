import { isPropValid } from 'src/utilities/isPropValid';

import { styled } from '@mui/material/styles';

const StyledIconButton = styled('button', {
    label: 'StyledCopyTooltipButton',
    shouldForwardProp: (prop) => isPropValid(['text'], prop),
  })(({ theme, ...props }) => ({
    position: 'relative',
    padding: 4,
    textAlign: 'left',
    backgroundColor: 'transparent',
    transition: theme.transitions.create(['background-color']),
    borderRadius: 4,
    border: 'none',
    cursor: 'pointer',
    color: theme.color.grey1,
    '& svg': {
      transition: theme.transitions.create(['color']),
      color: theme.color.grey1,
      margin: 0,
      position: 'relative',
      width: 20,
      height: 20,
    },
    '& svg:hover': {
      color: theme.palette.primary.main,
    }
  }));
  
export default StyledIconButton;
