import React from 'react';
import classNames from 'classnames';

import { IconButton } from '../IconButton';
import RefreshIcon from '../../assets/icons/reload.svg';

import { makeStyles } from '@mui/styles';
import { Theme, Tooltip } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 20,
    height: 20,
  },
  loading: {
    '& svg': {
      animation: `$rotate 2s linear infinite`,
    },
  },
  '@keyframes rotate': {
    from: {
      transform: 'rotate(-360deg)',
      to: 'rotate(-360deg)',
    },
  },
}));

interface RefreshButtonProps {
  loading?: boolean;
  onClick?: (e: any) => void;
}

const RefreshButton = (props: RefreshButtonProps) => {
  const classes = useStyles();

  const { loading, onClick } = props;

  return (
    <Tooltip title={loading ? 'Refreshing' : 'Refresh'} placement="top">
      <IconButton
        onClick={onClick}
        disabled={loading}
        className={classNames({
          [classes.loading]: loading,
        })}
      >
        <RefreshIcon className={classes.icon} />
      </IconButton>
    </Tooltip>
  );
};

export default RefreshButton;
