import { APIError } from '@bitnimbus/api-v4/lib/types';
import * as React from 'react';
import { Resource } from '@bitnimbus/api-v4';
import Header from './Header';
import ResourceDetails from './ResourceDetails';
import { Spec } from './ResourceSpec';
import DeleteResourceDialog from '../popup/DeleteResourceDialog';

interface Props {
  resource: Resource | null;
  error?: APIError[] | null;
  isLoading?: boolean;
  isRefetching?: boolean;
  title: string;
  isFailed?: boolean;
  price_hourly: number;
  specs: Spec[];
  updateLabel: (label: string) => Promise<void>;
  refetchResource: () => Promise<void>;
  updateCompleted: boolean;
  deleteLoading?: boolean;
  deleteError: APIError[] | null;
  path: string;
  deleteReset: () => void;
  handleDelete: () => Promise<void>;
  deleteEnabled: boolean;
  deleteDisabledMessage?: string;
  plan: string;
}

const Summary = (props: Props) => {
  const { resource, error, isLoading, isRefetching, updateLabel } = props;
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState<boolean>(
    false
  );

  const handleOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleClose = () => {
    setDeleteDialogOpen(false);
  };
  if (!resource) {
    return null;
  }

  return (
    <>
      <Header
        resource={resource}
        isFailed={props.isFailed}
        isRefetching={isRefetching}
        isLoading={isLoading || false}
        error={error || null}
        canUpdate={true}
        updateLabel={updateLabel}
        refetchResource={props.refetchResource}
        updateCompleted={props.updateCompleted}
        path={props.path}
      />
      <ResourceDetails
        title={props.title}
        resource={resource}
        price_hourly={props.price_hourly}
        specs={props.specs}
        onDeleteOpen={handleOpen}
        plan={props.plan}
      />
      <DeleteResourceDialog
        isLoading={isLoading || props.deleteLoading}
        label={resource.label}
        error={props.deleteError}
        reset={props.deleteReset}
        resourceId={resource.id}
        open={deleteDialogOpen}
        onClose={handleClose}
        handleDelete={props.handleDelete}
        enabled={props.deleteEnabled}
        disabledMessage={props.deleteDisabledMessage}
      />
    </>
  );
};

export default Summary;
