import React from 'react';
import cx from 'classnames';

import { Theme, Box, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

interface LabelValueDisplayProps {
  label: string;
  component?: React.ReactNode;
  value?: string;
  inline?: boolean;
  spacing?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    color: theme.textColors.headlineStatic,
    // fontFamily: theme.font.bold,
  },
  container: {
    fontSize: '1rem',
    color: theme.textColors.headlineStatic,
  },
  valueInline: {
    marginLeft: theme.spacing(2),
  },
  valueNewLine: {
    marginTop: theme.spacing(1.5),
  },
  valueText: {
    fontSize: '1rem',
    color: theme.textColors.textGrayShade,
  },
  containerInline: {
    margin: 0,
    display: 'flex',
    justifyItems: 'flex-start',
    alignItems: 'baseline',
  },
}));

const LabelValueDisplay = (props: LabelValueDisplayProps) => {
  const classes = useStyles();
  const { label, value, inline, component } = props;
  const theme = useTheme();

  return (
    <Box
      component="div"
      margin="4px"
      marginLeft={0}
      className={cx(classes.container, {
        [classes.containerInline]: inline,
      })}
    >
      <Typography
        variant="h3"
        className={cx({
          [classes.label]: true,
        })}
      >
        {label}
      </Typography>
      <Box
        component="div"
        className={cx(classes.valueText, {
          [classes.valueInline]: inline,
          [classes.valueNewLine]: !inline,
        })}
        style={
          props.spacing && inline
            ? {
                marginLeft: theme.spacing(props.spacing),
              }
            : {
                marginTop: theme.spacing(props.spacing),
              }
        }
      >
        {component ? (
          component
        ) : (
          <Typography variant="body1" className={classes.valueText}>
            {value}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default LabelValueDisplay;
