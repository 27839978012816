import React, { useState } from 'react';

import EyeIcon from 'src/assets/icons/eye.svg';
import HideIcon from 'src/assets/icons/hide.svg';
import StyledIconButton from 'src/components/StyledIconButton/StyledIconButton';

import { Box, Tooltip, useTheme } from '@mui/material';

interface MaskTextProps {
  text: string;
  className?: any;
}

const MaskText = (props: MaskTextProps) => {
  const theme = useTheme();
  const MASK_TEST = '\u2022'.repeat(20);

  const [isMasked, setIsMasked] = useState(true);

  const toggleIsMasked = () => {
    setIsMasked(!isMasked);
  };

  return (
    <Box
      component="div"
      display="flex"
      justifyContent="space-between"
      alignItems="baseline"
    >
      <Box
        component="div"
        width="96%"
        padding="4px"
        fontSize={isMasked ? '1.2rem' : '1rem'}
        className={props.className}
      >
        {isMasked ? MASK_TEST : props.text}
      </Box>
      <Box component="div">
        <Tooltip
          title={isMasked ? 'Show Password' : 'Hide Password'}
          placement="top"
        >
          <StyledIconButton
            aria-label={`Show text`}
            name={props.text}
            type="button"
            onClick={toggleIsMasked}
          >
            {isMasked ? (
              <EyeIcon />
            ) : (
              <HideIcon color={theme.palette.text.primary} />
            )}
          </StyledIconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default MaskText;
