import { ServiceType } from '@bitnimbus/api-v4';
import React, { useState, useEffect } from 'react';
import ResourceMetrics from 'src/features/Resource/ResourceMetrics';
import { useKafkaStatsByDateTime } from 'src/queries/kafka';
import { useMetricsQuery } from 'src/queries/metrics';

interface MetricsProps {
  vectordbId: string;
  createdAt: string;
}

const Metrics = (props: MetricsProps) => {
  const [timeRange, setTimeRange] = useState({
    from: new Date(Date.now() - 300 * 1000),
    to: new Date(),
  });

  const {
    data: stats,
    isLoading,
    error,
    refetch,
    isRefetching,
  } = useMetricsQuery(
    props.vectordbId,
    timeRange?.from,
    timeRange?.to,
    props.createdAt,
    ServiceType.VectorDB
  );

  useEffect(() => {
    if (timeRange) {
      refetch();
    }
  }, [timeRange]);

  const handleUpdate = (value: Record<string, Date>) => {
    setTimeRange({
      from: value.from,
      to: value.to,
    });
  };

  return (
    <div>
      <ResourceMetrics
        resourceId={props.vectordbId}
        createdAt={props.createdAt}
        updateTimeRange={handleUpdate}
        stats={stats}
        isLoading={isLoading || isRefetching}
        error={error}
      />
    </div>
  );
};

export default Metrics;
